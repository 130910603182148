import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: 'https://sun2000.herokuapp.com/graphql/',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token') || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzE2OTg4MjMsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjMxNjk5MTIzLCJ0b2tlbiI6IlFMQkppeXJoOVJwdCIsImVtYWlsIjoiZWlzZW5tYXh4QGdtYWlsLmNvbSIsInR5cGUiOiJhY2Nlc3MiLCJ1c2VyX2lkIjoiVlhObGNqbzVPREk9IiwiaXNfc3RhZmYiOnRydWV9.tNa0gAmIMhtDw4fBDvdSXMeqi1uqIlsj9IRf7N5PScQ'
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token ? { 'Authorization-Bearer': token } : {}),
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),

  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
  credentials: 'include',
})
