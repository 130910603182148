import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { State } from './state'

import { loader } from 'graphql.macro'
//const query = loader('./fixtures/query.graphql');
const productQuery = loader('./gql/product.gql')
const navQuery = loader('./gql/nav.gql')
const collectionsQuery = loader('./gql/collections.gql')
const collectionQuery = loader('./gql/collection.gql')
const searchQuery = loader('./gql/search.gql')
const registerMutation = loader('./gql/register.gql')
const pwResetMutation = loader('./gql/passwordreset.gql')
const authMutation = loader('./gql/auth.gql')
const deleteAddressMutation = loader('./gql/deleteAddress.gql')
const setAddressMutation = loader('./gql/setAddress.gql')
const addAddressMutation = loader('./gql/addAddress.gql')


const meQuery = loader('./gql/me.gql')
const siteQuery = loader('./gql/site.gql')
const pageQuery = loader('./gql/page.gql')
const categoryQuery = loader('./gql/category.gql')
const checkoutMutation = loader('./gql/checkout.gql')
const resetMutation = loader('./gql/reset.gql')
const checkoutQuery = loader('./gql/getcheckout.gql')

const updatesmpg = loader('./gql/smpg.gql');
const finalizeCheckoutMutation = loader('./gql/finalizecheckout.gql');

export const useUpdateCheckout = (vars) => {
  return useMutation(updatesmpg, vars);
}

export const useReset = (vars) => {
  return useMutation(resetMutation, vars);
}

export const useFinalizeCheckout = (vars) => {
  return useMutation(finalizeCheckoutMutation, vars);
}

export const useDeleteAddress = (vars) => {
  return useMutation(deleteAddressMutation, vars);
}

export const useSetAddress = vars => {
  return useMutation(setAddressMutation, vars);
}

export const useSite = () => useQuery(siteQuery);

export const useAddAddress = vars => useMutation(addAddressMutation, vars);

export const useUser = () => {
  const mail = localStorage.getItem('email')
  if (!mail) {
    return {
      email: mail,
    }
  }

  return { email: null }
}

export const useMe = () => {
  return useQuery(meQuery);
}

export const useCheckout = token => {
  return useQuery(checkoutQuery, {
    variables: {
      token,
    },
  })
}

export const useCreateCheckout = () => {
  return useMutation(checkoutMutation)
}

export const useRegister = () => {
  return useMutation(registerMutation)
}

export const useAuth = () => {
  return useMutation(authMutation)
}

export const useLogout = () => {
  return () => {
    localStorage.removeItem('token')
    localStorage.removeItem('checkout')
    localStorage.removeItem('email')
    localStorage.removeItem('react-user-cart')
    localStorage.removeItem("ti")
    window.location.reload()
  }
}

export const useResetPw = () => {
  return useMutation(pwResetMutation)
}

export const useSearch = () => {
  const [result, setResult] = useState({})
  const [q, r] = useLazyQuery(searchQuery)
  useEffect(() => {
    setResult(r)
  }, [r])
  return [q, result, setResult]
}

export const useNav = () => {
  const { channel, locale } = useContext(State)
  const LOCALE = locale.toUpperCase()
  return useQuery(navQuery, {
    variables: {
      languageCode: LOCALE,
      channel,
    },
  })
}

export const useCollections = () => {
  return useQuery(collectionsQuery, {
    variables: {
      first: 5,
    },
  })
}

export const usePage = slug => {
  const { locale } = useContext(State)
  const LOCALE = locale.toUpperCase()
  return useQuery(pageQuery, {
    variables: {
      slug,
      languageCode: LOCALE,
    },
  })
}

export const useProduct = slug => {
  const { channel } = useContext(State)
  const { locale } = useContext(State)
  const LOCALE = locale.toUpperCase()
  return useQuery(productQuery, {
    variables: {
      slug,
      channel,
      languageCode: LOCALE,
    },
  })
}

export const useCategory = (slug, pagination) => {
  const { channel } = useContext(State)
  const { locale } = useContext(State)
  const LOCALE = locale.toUpperCase()
  return useQuery(categoryQuery, {
    variables: {
      slug,
      channel,
      ...pagination,
      languageCode: LOCALE,
    },
  })
}

export const useCollection = (slug, pagination, sort = {}) => {
  const { channel } = useContext(State)
  const { locale } = useContext(State)
  const LOCALE = locale.toUpperCase()
  return useQuery(collectionQuery, {
    variables: {
      slug,
      channel,
      ...pagination,
      ...sort,
      languageCode: LOCALE,
    },
  })
}
