import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useProduct } from "./hooks";
import { useRouteMatch } from "react-router";
import { useCart } from "react-use-cart";
import { State } from "./state";
import { Button } from "./button";
import Output from "editorjs-react-renderer";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";


const Line = styled.div``;

export const ProductLine = styled(
  ({ className, onClick = (x) => x, amount, ...product }) => {
    const intl = useIntl();
    const slug = product?.slug;
    const name = product?.translation?.name || product?.name;

    const price = product?.pricing?.priceRange?.stop?.net || {
      amount: 0,
      currency: "EUR",
    };
    const img = product?.thumbnail?.url;
    return (
      <Link to={`/product/${slug}`} className={className} onClick={onClick}>
        <Line>
          <img src={img} alt={name} />
          <div>
            <span>{name}</span>
            <span>
              {amount && `${amount} x `}
              {intl.formatNumber(price.amount, {
                style: "currency",
                currency: price.currency,
              })}
            </span>
          </div>
        </Line>
      </Link>
    );
  }
)`
  color: black;
  user-select: none;
  ${Line} {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    justify-content: stretch;
    border-bottom: solid 1px black;
  }
  img {
    margin-right: 10px;
    width: 60px;
  }
  img + div {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const Grid = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProductBg = styled.div`
  width: 200px;
  min-height: 200px;
  color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  user-select: none;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  img {
    width: 100%;
  }

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ProductPage = styled(({ className }) => {
  const {
    params: { slug },
  } = useRouteMatch();
  const { data, error, loading } = useProduct(slug);
  const intl = useIntl();
  const cart = useCart();
  const { channel } = useContext(State);
  const [amount, setAmount] = useState(1);

  if (error || loading) return null;

  const { product } = data;
  const name = product?.translation?.name || product?.name;
  //const thumb = product?.thumbnail?.url;
  const price = product?.pricing?.priceRange?.stop?.net || {
    amount: 0,
    currency: "EUR",
  };

  const description = product?.translation?.description || product?.description;
  const attachments = product?.attributes
    ?.flatMap?.((p) => p.values)
    .filter((x) => !!x?.file?.url);
  return (
    <div className={className}>
      <Helmet>
        <title>Sun2000 - {product?.seoTitle}</title>
        <meta name="description" content={product?.seoDescription} />
      </Helmet>
      <div>
        <h1 className="title">{name}</h1>
        <div className="images">
          {product?.media?.map?.((media) => {
            if (media.type === "VIDEO") {
              return null;
            }
            return <img key={media?.url} src={media?.url} alt={name} />;
          })}
        </div>
      </div>
      <div>
        <div className="a">
          <h1>
            {intl.formatNumber(price.amount, {
              style: "currency",
              currency: price.currency,
            })}
          </h1>
            <div className="group" style={{
              marginLeft: 10
            }}>
              <input
                type="number"
                min="1"
                max="1000"
                value={amount}
                onChange={(e) => {
                  setAmount(+e.target.value);
                }}
              />
              <span className="error"></span>
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>
                <FormattedMessage id="Quantity" defaultMessage="Quantity" />
              </label>
            </div>
          
          <Button
            onClick={() => {
              cart.addItem(
                {
                  ...product,
                  name,
                  price: price.amount,
                  id: product?.variants?.[0].id,
                  currency: price.currency,
                  channel,
                },
                amount
              );
            }}
          >
            <FormattedMessage id="addToCart" defaultMessage="Add To Cart" />
          </Button>
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Output data={JSON.parse(description)} />
          {attachments?.length > 0 && (<FormattedMessage id="attachments" defaultMessage="Attachments:" />)}
          <br />
          <ul>
            {(attachments || []).map((a) => {
              const filename = a.file.url.split("file_upload/")[1];
              return (
                <li>
                  <a
                    href={`https://sun2000.fra1.digitaloceanspaces.com/file_upload/${filename}`}
                    download={filename}
                  >
                    {filename}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
})`
--bgcolor: #424242;
--hlcolor: var(--primaryColor);
--mutedcolor: black;
--transtime: 300ms;
--width: 100%;
summary {
  ::marker,
  ::-webkit-details-marker {
    display: none;
  }
  list-style: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
  :after {
    right: -30px;
    top: -12px;
    width: 40px;
    height: 40px;
    position: absolute;

    content: "";
    border-radius: 50%;
    background: var(--primaryBackgroundColor);
    transition: transform 0.3s;
    background-position: center;
    background-repeat: no-repeat;
    // TODO: Remove
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xMC41OSAwTDYgNC4zMjY1OUwxLjQxIDBMMCAxLjMzMTk4TDYgN0wxMiAxLjMzMTk4TDEwLjU5IDBaIiBmaWxsPSIjRkY1QTVBIi8+PC9zdmc+);
  }
}

margin-bottom: 20px;
width: var(--width);
input[type="password"] {
  letter-spacing: 0.3em;
}

input:required ~ label:after {
  content: "*";
  display: block;
  position: absolute;
  right: -12px;
  top: 0;
  width: 10px;
  height: 10px;
  color: red;
}

.group {
  position: relative;
  margin: 15px 0;
}
.bar {
  position: relative;
  display: block;
  width: var(--width);
  &:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: var(--hlcolor);
    transition: var(--transtime) ease all;
    left: 0%;
  }
}

display: flex;
flex-direction: column;

input,
textarea,
select {
  background: none;
  color: var(--mutedcolor);
  font-size: 18px;
  padding: 10px 10px 10px 5px;

  display: block;
  width: var(--width);
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--mutedcolor);
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:invalid ~ label {
    top: -4px;
    font-size: 12px;
  }
  &:focus ~ .bar:before {
    width: var(--width);
  }
}

select {
  padding-left: 0;
}

> strong {
  margin: 10px 0;
  padding: 10px 10px 10px 5px;
  font-size: 20px;
}

label {
  color: var(--mutedcolor);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: var(--transtime) ease all;
}

label:not(:placeholder-shown) {
  top: -4px;
  font-size: 12px;
}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 20px;
  user-select: none;
  .title {
    min-height: 78px;
  }
  > div {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  > div:nth-of-type(2) {
    align-items: flex-end;
    justify-content: stretch;

    button {
      margin: 20px 10px;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .images {
    display: grid;
    gap: 20px;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    img {
      max-width: min(450px, 100%);
      max-height: 450px;
      width: auto;
      height: fit-content;
    }
  }

  @media (max-width: 700px) {
    .a,
    .images {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;
      align-items: center;
      img {
        width: fit-content;
      }
    }
    h1 {
      text-align: center;
    }
    &,
    > div {
      justify-content: center;
      align-items: start;
    }
  }
`;

export const Product = styled(({ className, ...product }) => {
  const intl = useIntl();
  const slug = product?.slug;
  const name = product?.translation?.name || product?.name;
  const price = product?.pricing?.priceRange?.stop?.net || {
    amount: 0,
    currency: "EUR",
  };
  return (
    <Link to={`/product/${slug}`} className={className}>
      <ProductBg>
        <div>
          <img src={product.thumbnail.url} alt={name} />
        </div>

        <h1>{name}</h1>
        <h2>
          {intl.formatNumber(price.amount, {
            style: "currency",
            currency: price.currency,
          })}
        </h2>
      </ProductBg>
    </Link>
  );
})`
  display: block;
`;
