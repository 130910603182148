import styled from "styled-components";
import React, { useCallback, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useCollection, useNav } from "./hooks";
import { Pagination } from "./pagination";
import { Grid, Product } from "./product";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Form } from "./page";

export const CollectionPage = styled((props) => {
  const {
    params: { slug },
  } = useRouteMatch();
  const intl = useIntl();
  const [sort, setSort] = useState({
    direction: "ASC",
    field: "PRICE",
  });
  const [current, setCurrent] = useState(undefined);
  const { data, error, loading } = useCollection(slug, current, sort);
  const setSortCallback = useCallback(
    (type = "direction") =>
      (e) => {
        setSort({ [type]: e.target.value });
      },
    [setSort]
  );
  if (error) return null;

  const name = data?.collection?.translation?.name || data?.collection?.name;
  const totalCount = data?.collection?.products?.totalCount;
  const pages = (totalCount - (totalCount % 10) + 10) / 10;
  const pageInfo = data?.collection?.products?.pageInfo;
  return (
    <div className={props.className}>
      <Helmet>
        <title>Sun2000 {data?.collection?.seoTitle || ""}</title>
        <meta
          name="description"
          content={data?.collection?.seoDescription || ""}
        />
      </Helmet>
      <Form>
        <h2>
          {intl.formatMessage({
            id: "Sort",
            defaultMessage: "Sort",
          })}
        </h2>
        <div className="group">
          <select
            onChange={setSortCallback("direction")}
            defaultValue={sort.direction}
          >
            <option
              key="desc"
              value="DESC"
              label={intl.formatMessage({
                defaultMessage: "Desc",
                id: "sortOrderDesc",
              })}
            />
            <option
              key="asc"
              value="ASC"
              label={intl.formatMessage({
                defaultMessage: "Asc",
                id: "sortOrderAsc",
              })}
            />
          </select>
          <select onChange={setSortCallback("field")} defaultValue={sort.field}>
            <option
              value="NAME"
              key="NAME"
              label={intl.formatMessage({
                defaultMessage: "Name",
                id: "sortByNAME",
              })}
            />
            <option
              value="PRICE"
              key="PRICE"
              label={intl.formatMessage({
                defaultMessage: "Price",
                id: "sortByPRICE",
              })}
            />
            <option
              value="DATE"
              key="DATE"
              label={intl.formatMessage({
                defaultMessage: "Date",
                id: "sortByDATE",
              })}
            />
            <option
              value="TYPE"
              key="TYPE"
              label={intl.formatMessage({
                defaultMessage: "Type",
                id: "sortByTYPE",
              })}
            />
            <option
              value="PUBLICATION_DATE"
              key="PUBLICATION_DATE"
              label={intl.formatMessage({
                defaultMessage: "Publication Date",
                id: "sortByPUBLICATION_DATE",
              })}
            />
          </select>
        </div>
      </Form>
      <h1>{name}</h1>
      <Grid>
        {loading && "..."}
        {!loading &&
          data?.collection?.products?.edges?.map?.(({ node: product }) => (
            <Product key={product.id} {...product} />
          ))}
      </Grid>
      <Pagination
        onChange={setCurrent}
        current={0}
        pages={pages}
        {...pageInfo}
      />
    </div>
  );
})`
  padding: 40px 0;
  h1 {
    text-align: center;
    margin: 20px 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Form} {
    display: flex;
    .group {
      display: flex;
      select {
        flex: 1;
      }
      select:last-of-type {
        flex: 3;
      }

    }

    padding: 20px;
    max-width: 400px;
  }
`;

export const Navs = () => {
  const { data, error, loading } = useNav();
  if (error || loading) return null;
  return (
    <>
      {data.menu.items.map((node) => {
        const type = node?.collection || node?.category || node?.page;
        const slug = type?.slug;
        const name =
          type?.translation?.name ||
          type?.translation?.title ||
          type?.name ||
          type?.title;

        return (
          <Link
            key={slug + name}
            to={`/${type?.__typename.toLowerCase()}/${slug}`}
          >
            {name}
          </Link>
        );
      })}
    </>
  );
};
