import styled from 'styled-components'

export const Button = styled.button`
  background: #fff;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.small {
    padding: 4px 6px;
  }
  &:hover {
    box-shadow: 0 0px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    border: solid 1px grey;
    opacity: .8;
    cursor: not-allowed;
  }
  &.btn-link {
    background: var(--primaryColor);
    color: mix(white, var(--primaryColor), 80%);
    &:hover {
      background: darken(var(--primaryColor), 5%);
      color: mix(white, var(--primaryColor), 85%);
    }
  }

  border: solid 1px black;
  color: black;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    border: solid 1px var(--primaryColor);
    color: var(--primaryColor);
  }

  &.btn-cancel {
    background: #eee;
    &:hover {
      background: darken(#eee, 5%);
      color: mix(black, black, 30%);
    }
  }
`
