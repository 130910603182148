import styled from 'styled-components'

export const Pagination = styled(
  ({
    className,
    pages = 4,
    current = 0,
    hasNextPage,
    hasPreviousPage,
    onChange,
    endCursor,
    startCursor,
  }) => {
    return (
      <nav aria-label="pagination" className={className}>
        <ul>
          {hasPreviousPage && (
            <li>
              <a
                href="#prev"
                onClick={e => {
                  e.preventDefault()
                  onChange({ before: startCursor })
                }}
              >
                <span aria-hidden="true">«</span>
                <span className="visuallyhidden">previous set of pages</span>
              </a>
            </li>
          )}
          {false &&
            new Array(pages).fill(0).map((_, i) => (
              <li key={i}>
                <a
                  href={`#page${i}`}
                  {...(i === current ? { 'aria-current': 'page' } : {})}
                  onClick={() => onChange(i)}
                >
                  <span className="visuallyhidden">page </span>
                  {i + 1}
                </a>
              </li>
            ))}

          {hasNextPage && (
            <li>
              <a
                href="#next"
                onClick={e => {
                  e.preventDefault()
                  onChange({ after: endCursor })
                }}
              >
                <span className="visuallyhidden">next set of pages</span>
                <span aria-hidden="true">»</span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    )
  }
)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  > ul > li {
    margin: 0 5px;

    a {
      display: block;
      padding: 0.5em 1em;
      border: 1px solid #999;
      border-radius: 0.2em;
      text-decoration: none;
      background-color: white;
      color: black;
    }

    a[aria-current='page'] {
      background-color: white;
      color: var(--primaryColor);
    }
  }
`
