import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import {
  useAuth,
  useCollection,
  usePage,
  useRegister,
  useReset,
  useResetPw,
} from "./hooks";
import Output from "editorjs-react-renderer";
import { Grid, Product } from "./product";
import { Button } from "./button";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";

export const Form = styled.form`
  --bgcolor: #424242;
  --hlcolor: var(--primaryColor);
  --mutedcolor: black;
  --transtime: 300ms;
  --width: 100%;
  summary {
    ::marker,
    ::-webkit-details-marker {
      display: none;
    }
    list-style: none;
    cursor: pointer;
    position: relative;
    width: fit-content;
    :after {
      right: -30px;
      top: -12px;
      width: 40px;
      height: 40px;
      position: absolute;

      content: "";
      border-radius: 50%;
      background: var(--primaryBackgroundColor);
      transition: transform 0.3s;
      background-position: center;
      background-repeat: no-repeat;
      // TODO: Remove
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xMC41OSAwTDYgNC4zMjY1OUwxLjQxIDBMMCAxLjMzMTk4TDYgN0wxMiAxLjMzMTk4TDEwLjU5IDBaIiBmaWxsPSIjRkY1QTVBIi8+PC9zdmc+);
    }
  }

  margin-bottom: 20px;
  width: var(--width);
  input[type="password"] {
    letter-spacing: 0.3em;
  }

  input:required ~ label:after {
    content: "*";
    display: block;
    position: absolute;
    right: -12px;
    top: 0;
    width: 10px;
    height: 10px;
    color: red;
  }

  .group {
    position: relative;
    margin: 15px 0;
  }
  .bar {
    position: relative;
    display: block;
    width: var(--width);
    &:before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: var(--hlcolor);
      transition: var(--transtime) ease all;
      left: 0%;
    }
  }

  display: flex;
  flex-direction: column;

  input,
  textarea,
  select {
    background: none;
    color: var(--mutedcolor);
    font-size: 18px;
    padding: 10px 10px 10px 5px;

    display: block;
    width: var(--width);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--mutedcolor);
    &:focus {
      outline: none;
    }
    &:focus ~ label,
    &:invalid ~ label {
      top: -4px;
      font-size: 12px;
    }
    &:focus ~ .bar:before {
      width: var(--width);
    }
  }

  select {
    padding-left: 0;
  }

  > strong {
    margin: 10px 0;
    padding: 10px 10px 10px 5px;
    font-size: 20px;
  }

  label {
    color: var(--mutedcolor);
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: var(--transtime) ease all;
  }

  label:not(:placeholder-shown) {
    top: -4px;
    font-size: 12px;
  }
`;


export const AuthPage = styled(({ className, type = "login" }) => {
  const [auth, { data: authData, error: authError }] = useAuth();
  const [register, { data: registerData, error: registerError }] =
    useRegister();
  const [pwreset, { data: pwResetData, error: resetError }] = useResetPw();

  const params = useParams();
  const [resetPw] = useReset();

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const email = formData.get("email");
      const password = formData.get("password");
      const password2 = formData.get("password2");
      switch (type) {
        case "login":
          return auth({
            variables: {
              email,
              password,
            },
          }).then(r => {
            let data = r.data;
            if (r.data?.tokenCreate?.errors?.length === 0) {

            }

            if(!data?.tokenCreate?.token) {
              return;
            }

            localStorage.setItem("token", data?.tokenCreate?.token);
            localStorage.setItem("email", data?.tokenCreate?.user?.email);
            let t = data.tokenCreate.user?.metadata?.find?.((x) => x.key === "ti" || x.key === "ti");
            localStorage.setItem(
              "ti",
              t?.value === "1" || t?.value === "true" ? "true" : "false"
            );
            window.location.pathname = "/";
          });
        case "reset":
          return resetPw({
            variables: {
              email: params.email,
              token: params.token,
              pw: formData.get("password")
            }
          })
        case "register":
          if (password !== password2) {
            return;
          }
          return register({
            variables: {
              email,
              password,
            },
          }).then(r => {
            if (r.data) {
              debugger;
              window.location.pathname = "/auth";
            }
          });
        case "pwreset":
          return pwreset({
            variables: {
              email,
              back: window.location.origin,
            },
          });
        default:
          return;
      }
    },
    [type, auth, pwreset, register, params, resetPw]
  );

  useEffect(() => {
    console.log(registerData);
  }, [registerData]);

  return (
    <div className={className}>
      <div>
        {type === "login" && (
          <>
            <h1>
              <FormattedMessage id="login" defaultMessage="Login" />
            </h1>
            <p>{authError && authError.message}</p>
            <p>
              {authData?.tokenCreate?.errors?.map?.((error) => (
                <span>{error.message}</span>
              ))}
            </p>
            <Form action="#" onSubmit={submit}>
              <div className="group">
                <input type="email" name="email" required="required" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="email" defaultMessage="Email" />
                </label>
              </div>
              <div className="group">
                <input type="password" name="password" required="required" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="password" defaultMessage="Password" />
                </label>
              </div>

              <Button type="submit">
                <FormattedMessage id="login" defaultMessage="Login" />
              </Button>
            </Form>
            <Link to="/register">
              <FormattedMessage
                id="newRegister"
                defaultMessage="New here? Register!"
              />
            </Link>
            <Link to="/auth/reset">
              <FormattedMessage
                id="forgotPassword"
                defaultMessage="Forgot password? Click here!"
              />
            </Link>
          </>
        )}

        {type === "register" && (
          <>
            <h1>
              <FormattedMessage id="register" defaultMessage="Register" />
            </h1>
            <p>{registerError && registerError.message}</p>
            <p>
              {registerData?.accountRegister?.errors?.map?.((error) => (
                <span>{error.message}</span>
              ))}
            </p>
            <Form action="#" onSubmit={submit}>
              <div className="group">
                <input type="email" name="email" required="required" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="email" defaultMessage="Email" />
                </label>
              </div>
              <div className="group">
                <input
                  type="password"
                  name="password"
                  required="required"
                  minLength="6"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="password" defaultMessage="Password" />
                </label>
              </div>
              <div className="group">
                <input
                  type="password"
                  name="password2"
                  required="required"
                  minLength="6"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage
                    id="repeatPassword"
                    defaultMessage="Repeat password"
                  />
                </label>
              </div>

              <Button type="submit">
                <FormattedMessage id="register" defaultMessage="Register" />
              </Button>
            </Form>
            <Link to="/auth/reset">
              <FormattedMessage
                id="forgotPassword"
                defaultMessage="Forgot password? Click here!"
              />
            </Link>
          </>
        )}
        {type === "pwreset" && (
          <>
            <h1>
              <FormattedMessage
                id="resetPassword"
                defaultMessage="Reset password"
              />
            </h1>
            <p>{resetError && resetError.message}</p>
            <p>
              {pwResetData?.requestPasswordReset?.errors?.map?.((error) => (
                <span>{error.message}</span>
              ))}
            </p>
            <Form action="#" onSubmit={submit}>
              <div className="group">
                <input type="email" name="email" required="required" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="email" defaultMessage="Email" />
                </label>
              </div>

              <Button type="submit">
                <FormattedMessage id="reset" defaultMessage="Reset" />
              </Button>
            </Form>
          </>
        )}
        {type === "reset" && (
          <>
            <h1>
              <FormattedMessage
                id="resetPassword"
                defaultMessage="Reset password"
              />
            </h1>
            <p>{resetError && resetError.message}</p>
            <p>
              {pwResetData?.requestPasswordReset?.errors?.map?.((error) => (
                <span>{error.message}</span>
              ))}
            </p>
            <Form action="#" onSubmit={submit}>
              <div className="group">
                <input type="password" name="password" required="required" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="password" defaultMessage="Password" />
                </label>
              </div>

              <Button type="submit">
                <FormattedMessage id="reset" defaultMessage="Reset" />
              </Button>
            </Form>
          </>
        )}
      </div>
    </div>
  );
})`
  // VARIABLES // ============================== //
  --bgcolor: #424242;
  --hlcolor: var(--primaryColor);
  --mutedcolor: black;
  --transtime: 300ms;
  --width: 300px;
  background: white;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: center;
  > div {
    width: var(--width);
  }

  h1 {
    color: black;
    margin: 20px 0;
  }
  p {
    margin: 20px 0;
    color: red;
  }

  a {
    color: black;
    display: block;
    margin: 10px 0;
  }

  ${Button} {
    width: var(--width);
  }

  padding: 10px;
  display: flex;
  height: 100%;
  min-height: 70vh;

  justify-content: center;
  flex-direction: column;
`;

const Section = styled(({ className, title, label, description, image }) => {
  return (
    <div className={className}>
      {title && <h1>{title}</h1>}
      <div>
        <div>
          <h2>{label}</h2>
          <p>{description}</p>
        </div>
        <img src={image} alt="h1img" />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  color: black;
  padding: 20px;
  box-sizing: border-box;
  box-sizing: border-box;
  flex-wrap: wrap;

  max-width: 1280px;
  margin: 0 auto;

  h1 {
    margin: 0;
    padding: 40px 0;
    text-align: center;
  }

  h2 {
    padding: 20px 0;
  }

  > div {
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;

    > div {
      order: 0;
      flex: 1 1 50%;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    > img {
      max-width: 100%;
      height: fit-content;
      flex: 0 0 30%;
      margin: 10px;
      ${({ $imageFirst = false }) => $imageFirst && `order: -1;`}
    }
  }
`;

const Feature = styled.div`
  max-width: 30%;
  min-width: 290px;
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  justify-content: stretch;
  text-align: center;
  img {
    margin-bottom: auto;
    max-width: 100%;
    height: fit-content;
  }
  h1 {
    margin: 0;
    padding: 40px 0;
    text-align: center;
  }

  h2 {
    padding: 20px 0;
  }
`;

const Featured = styled(({ className }) => {
  const { data, error, loading } = useCollection("featured");
  if (error || loading) return null;
  return (
    <div className={className}>
      {data?.collection?.products?.edges?.map?.(({ node: product }) => (
        <Product key={product.id} {...product} />
      ))}
    </div>
  );
})`
  display: flex;
  overflow: scroll;
  justify-content: flex-start;
  padding: 20px;

  scroll-snap-type: both;

  ${Product} {
    scroll-snap-align: center;

    margin: 0 20px;
  }
`;

const Hero = styled.div`
  user-select: none;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  h1,
  h2,
  h3 {
    max-width: 320px;
    text-align: center;
    text-shadow: 1px 1px black;
    z-index: 0;
  }
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #464646;
    opacity: 0.4;
    z-index: 0;
  }
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ img }) => img};
`;

const Quotes = styled.div`
  svg {
    width: 40px;
    height: 40px;
    margin: 20px;
  }
  background: white;
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  > div {
    flex-grow: 1;
    text-align: center;
    flex: 1 1 25%;
    max-width: 25%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
  }
  p {
    flex-grow: 1;
  }

  cite {
    display: block;
    margin: 40px 0;
  }
`;

export const HomePage = styled(({ className }) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <Hero
        img={`url('https://cdn.shopify.com/s/files/1/0477/5506/7541/files/BANNER_JPG.JPG?v=1599486264')`}
      >
        <h1>
          <FormattedMessage
            id="Hero1Title"
            defaultMessage="SUN2000.HU HIVATALOS HUAWEI DISZTRIBÚTOR"
          />
        </h1>
        <h3>
          <FormattedMessage
            id="Hero1Subtitle"
            defaultMessage="Segítünk minőségi módon kiszolgáni Ügyfeleit! A SUN2000.hu az Ön Huawei inverter beszállítója."
          />
        </h3>
      </Hero>
      <Section
        title={intl.formatMessage({
          id: "section1Title",
          defaultMessage: "A jövő megérkezett: új luna2000 akkumulátor",
        })}
        description={intl.formatMessage({
          id: "section1Description",
          defaultMessage:
            "100% -os önfogyasztás elérése energia optimalizálással, 5kWh moduláris design, 5-10-15 kWh tároló rendszer, mely párhuzamosítva akár 30kWh tárolására is képes!",
        })}
        label={intl.formatMessage({
          id: "section1Label",
          defaultMessage: "ELÉRKEZETT AZ ENERGIATÁROLÁS JÖVŐJE!",
        })}
        image={intl.formatMessage({
          id: "section1Image",
          defaultMessage:
            "//cdn.shopify.com/s/files/1/0477/5506/7541/files/1_300x300.jpg?v=1612192584",
        })}
      />
      <Section
        $imageFirst
        label={intl.formatMessage({
          id: "section2Label",
          defaultMessage: "ÚJ GENERÁCIÓS SUN2000-L1",
        })}
        image={intl.formatMessage({
          id: "section2Image",
          defaultMessage:
            "//cdn.shopify.com/s/files/1/0477/5506/7541/files/Front-1_200x200.png?v=1599398743",
        })}
        description={intl.formatMessage({
          id: "section2Description",
          defaultMessage:
            "Ami eddig elképzelhetetlen volt, most lehetséges: – optimalizálók napelemekre, csak ott, ahol erre szükség van, – akkumulátorinterfész, direkt akkumulátor csatlakozás, – természetes hűtés, csendes, megbízható üzem, – biztosítékmentes kivitel, II-es szintű DC és AC túlfeszültség védelem, – összes sztring online felülvizsgálat, – mindezek gyönyörű, esztétikus kivitelben, – ellenállhatatlan árakon.",
        })}
      />
      <Featured />
      <Grid>
        <Feature>
          <img
            src="//cdn.shopify.com/s/files/1/0477/5506/7541/files/garanciak_300x300.JPG?v=1599487844"
            alt="garanciak"
          />
          <h1>
            <FormattedMessage
              id="Feature1Title"
              defaultMessage="Verhetelen garanciális ajánlatok"
            />
          </h1>
          <p>
            <FormattedMessage
              id="Feature1Description"
              defaultMessage="Inverterekre 10 év csereszavatosság akár 20 évre bővíthető. Az új optimalizálókra 25
            teljeskörű garancia. A további kiegészítőkre pedig 2 év jótállás biztosított vásárlóink
            részére."
            />
          </p>
        </Feature>
        <Feature>
          <img
            src="//cdn.shopify.com/s/files/1/0477/5506/7541/files/fusion_solar_300x300.JPG?v=1599488022"
            alt="fusion solar"
          />
          <h1>
            <FormattedMessage
              id="Feature2Title"
              defaultMessage="Fusion Solar alkalmazás"
            />
          </h1>
          <p>
            <FormattedMessage
              id="Feature2Description"
              defaultMessage="A FusionSolar alkalmazás lehetővé teszi a napelemes rendszer teljeskörű távfelügyeletét, így biztosít nyugalmat a hosszú garanciaidő alatt. Belépéshez kattintson!"
            />
          </p>
        </Feature>
        <Feature>
          <img
            src="//cdn.shopify.com/s/files/1/0477/5506/7541/files/intersolar_award_winner_300x300.jpg?v=1599488278//cdn.shopify.com/s/files/1/0477/5506/7541/files/intersolar_award_winner_300x300.jpg?v=1599488278"
            alt="award"
          />
          <h1>
            <FormattedMessage
              id="Feature3Title"
              defaultMessage="Díjnyertes technológia"
            />
          </h1>
          <p>
            <FormattedMessage
              id="Feature3Description"
              defaultMessage=" A Huawei Sun2000 inverter termékcsaládja nyerte a legnagyobb európai napelemes kiállítás az Intersolar termékdíját. A folyamatos fejlesztéseknek köszönhetően Ön a leginnovatívabb inverterek tulajdonosa lehet."
            />
          </p>
        </Feature>
      </Grid>
      <Hero
        img={
          'url("https://cdn.shopify.com/s/files/1/0477/5506/7541/files/inverter.JPG?v=1599488649")'
        }
      >
        <h1>
          <FormattedMessage
            id="Hero2Title"
            defaultMessage="LEGYEN ÖN IS ELÉGEDETT VÁSÁRLÓNK!"
          />
        </h1>
        <h3>
          <FormattedMessage
            id="Hero2Subtitle"
            defaultMessage="WWW.SUN2000.HU HIVATALOS HUAWEI NAGYKERESKEDÉS!"
          />
        </h3>
      </Hero>

      <Quotes>
        <div>
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 41 35"
          >
            <path
              d="M10.208 17.711h6.124v16.332H0V21.684C0 8.184 5.444.956 16.332 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766zm24.498 0h6.124v16.332H24.498V21.684C24.498 8.184 29.942.956 40.83 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766z"
              fill="#000"
              fillRule="evenodd"
            ></path>
          </svg>
          <p>
            <FormattedMessage
              id="Recommend1Text"
              defaultMessage="A rendelésünket már másnap futárszolgálat vitte a Megrendelő címére. Így számunkra a kivitelezés sokkal egyszerűbb volt. A kedvező árak pedig nagyobb hasznot eredményeztek!"
            />
          </p>
          <cite>
            <FormattedMessage
              id="Recommend1From"
              defaultMessage="Szabó Zsolt Napelem szerelő"
            />
          </cite>
        </div>
        <div>
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 41 35"
          >
            <path
              d="M10.208 17.711h6.124v16.332H0V21.684C0 8.184 5.444.956 16.332 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766zm24.498 0h6.124v16.332H24.498V21.684C24.498 8.184 29.942.956 40.83 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766z"
              fill="#000"
              fillRule="evenodd"
            ></path>
          </svg>
          <p>
            <FormattedMessage
              id="Recommend2Text"
              defaultMessage="Mindíg is problémát jelentett az inverterek jó áron történő beszerzése. Amióta a Sun2000 oldalról rendelünk folymatos ellátást és a legjobb árakat kapjuk."
            />
          </p>
          <cite>
            <FormattedMessage
              id="Recommend2From"
              defaultMessage="István Viszonteladó"
            />
          </cite>
        </div>
        <div>
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 41 35"
          >
            <path
              d="M10.208 17.711h6.124v16.332H0V21.684C0 8.184 5.444.956 16.332 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766zm24.498 0h6.124v16.332H24.498V21.684C24.498 8.184 29.942.956 40.83 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766z"
              fill="#000"
              fillRule="evenodd"
            ></path>
          </svg>
          <p>
            <FormattedMessage
              id="Recommend3Text"
              defaultMessage=" Elégedett vagyok a kapott szolgáltatással. Folymatosan nő az igény a Huawei inverterek
            iránt, melyet a Sun2000 csapatával könnyű kielégíteni"
            />
          </p>
          <cite>
            <FormattedMessage
              id="Recommend3From"
              defaultMessage="Ében Péter Zsolt"
            />
          </cite>
        </div>
      </Quotes>
    </div>
  );
})`
  user-select: none;
  > ${Grid} {
    background: white;
    color: black;
    padding: 20px;
  }
`;

export const OrderPlaced = (props) => {
  const {
    params: { id },
  } = useRouteMatch();

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <h2 style={{
        margin: "20px 0"
      }}>
        <FormattedMessage
          id="orderPlaced"
          defaultMessage="Your order has been placed."
        />
      </h2>
      <p>
       <FormattedMessage
          id="checkYourMailOrderId"
          defaultMessage="Your order's id is {id}."
          values={{
            id
          }}
        />
      </p>
      <br/>
      <p>
        <FormattedMessage
          id="checkYourMail"
          defaultMessage="For furher details please check your confirmation email."
        />
      </p>
    </div>
  );
};

export const Page = () => {
  const {
    params: { slug },
  } = useRouteMatch();
  const { data, error, loading } = usePage(slug);
  if (error || loading) return null;
  return (
    <>
      <Helmet>
        <title>Sun2000 - {data?.page?.seoTitle}</title>
        <meta name="description" content={data?.page?.seoDescription} />
      </Helmet>
      <Output
        data={JSON.parse(
          data.page?.translation?.content || data?.page?.content
        )}
      />
    </>
  );
};
