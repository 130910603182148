import styled, { createGlobalStyle } from "styled-components";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import React, { useCallback, useContext, useState } from "react";
import { State } from "./state";
import { Navs, CollectionPage } from "./collection";
import { CategoryPage } from "./category";
import { AuthPage, Form, HomePage, Page, OrderPlaced } from "./page";
import { ProductLine, ProductPage } from "./product";
import { CartProvider, useCart } from "react-use-cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { snakeCase } from "change-case";
import {
  faUser,
  faQuestionCircle,
  faPhone,
  faStar,
  faLandmark,
  faTrash,
  faSearch,
  faTimes,
  faSignInAlt,
  faMailBulk,
  faMapMarker,
  faList,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  useCreateCheckout,
  useLogout,
  useSearch,
  useUpdateCheckout,
  useFinalizeCheckout,
  useMe,
  useDeleteAddress,
  useSetAddress,
  useAddAddress,
  useSite,
} from "./hooks";
import { Button } from "./button";
import { useEffect, useRef } from "react";
import en from "./compiled-lang/en.json";

//import ReactLanguageSelect from 'react-languages-select'
//import 'react-languages-select/css/react-languages-select.css'

//const LanguagePicker = styled(({ className }) => {
//  return <div className={className}></div>
//})``

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    --primaryColor: #c60b18;
    padding: 0;
    margin: 0;
    background-color: white;
    color: black;
    min-height: 100%;
    height: 100%;
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
  }
  * {
    box-sizing: border-box;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  a {
    text-decoration: none;
  }
`;

const Logo = styled.img`
  width: 32px;
  cursor: pointer;
  aspect-ratio: 1;
  height: 32px;
`;

const Nav = styled.nav`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    padding: 10px;
    color: black;
    text-decoration: none;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: none;
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  box-shadow: 0 0 18px 0px #0000006e;
  display: flex;
  flex-direction: row;
  height: 64px;
  background: white;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  flex: 0 0 auto;
  color: black;
  z-index: 1;

  ${Nav} {
    a {
      margin-left: 10px;
    }
  }

  label[for="menu"] {
    display: none;
  }

  @media (max-width: 700px) {
    label[for="menu"] {
      display: block;
      min-width: 24px;
    }
    justify-content: space-between;
    ${Nav} {
      position: absolute;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      height: 0px;
      top: 0px;
      padding-top: 64px;
      z-index: -1;
      display: flex;
      flex-direction: column;
      background: white;
    }

    #menu:checked + ${Logo} + ${Nav} {
      box-shadow: 0 20px 18px 0px #0000006e;
      min-height: 10px;
      height: auto;
    }

    #menu:checked + ${Logo} + ${Nav} + label {
      ${Backdrop} {
        display: block;
        z-index: -2;
      }
    }
  }
`;
const Main = styled.main`
  width: 100%;
  height: auto;
  padding: 0px;
  overflow-x: hidden;
  max-width: 100vw;
  box-sizing: border-box;
`;

const Footer = styled.footer`
  color: black;
  flex: 0 0 auto;
  min-height: 100px;
  width: 100%;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 18px 0px #0000006e;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Hamburger = styled(({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="presentation"
    viewBox="0 0 37 40"
  >
    <path d="M33.5 25h-30c-1.1 0-2-.9-2-2s.9-2 2-2h30c1.1 0 2 .9 2 2s-.9 2-2 2zm0-11.5h-30c-1.1 0-2-.9-2-2s.9-2 2-2h30c1.1 0 2 .9 2 2s-.9 2-2 2zm0 23h-30c-1.1 0-2-.9-2-2s.9-2 2-2h30c1.1 0 2 .9 2 2s-.9 2-2 2z"></path>
  </svg>
))`
  cursor: pointer;
`;

const UserPopup = styled(({ className, logout }) => {
  return (
    <div className={className}>
      <input type="checkbox" id="userpopup" hidden />
      <label htmlFor="userpopup">
        <FontAwesomeIcon icon={faUser} />
      </label>

      <div>
        <ul>
          <li>
            <FontAwesomeIcon icon={faMapMarker} />
            <a href="/user/addresses">
              <FormattedMessage id="addresses" defaultMessage="Addresses" />
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faList} />
            <a href="/user/orders">
              <FormattedMessage id="pastOrders" defaultMessage="Past orders" />
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <a href="/logout" onClick={logout}>
              <FormattedMessage id="logout" defaultMessage="Logout" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
})`
  #userpopup + label + div:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background: white;
    width: 10px;
    height: 10px;
  }

  position: relative;
  #userpopup + label + div {
    display: none;
    position: absolute;
    top: 34px;
    left: 0;
    transform: translateX(calc(-50% + 7px));
    min-width: 170px;
    min-height: 40px;
    background: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  }

  #userpopup:checked + label[for="userpopup"]:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  #userpopup:checked + label + div {
    display: block;
  }

  ul {
    gap: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    list-style: none;
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid lightgrey;
    }
    li:last-of-type {
      border: none;
    }
    li:before {
      content: "";
      height: 2px;
      width: 0%;
      bottom: -1px;
      position: absolute;
      background: red;
      -webkit-transition: 0.3s ease all;
      transition: 0.3s ease all;
      left: 0%;
    }

    li:hover:before {
      width: 100%;
    }
  }
  a {
    margin-left: 5px;

    color: black;
  }
`;

const ContactPopup = styled(({ className }) => {
  const { data } = useSite();

  return (
    <div className={className}>
      <input type="checkbox" id="contactpopup" hidden />
      <label htmlFor="contactpopup">
        <FontAwesomeIcon icon={faPhone} />
      </label>

      <div>
        <ul>
          <li>
            <FontAwesomeIcon icon={faQuestionCircle} />
            <a href="#questions">
              <FormattedMessage
                id="questions"
                defaultMessage="You have a question?"
              />
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faPhone} />
            <a href={`tel:${data?.shop?.companyAddress?.phone}`}>
              <FormattedMessage id="callUs" defaultMessage="Call us" />
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faMailBulk} />
            <a href={`mailto:info@${window.location.hostname}`}>
              <FormattedMessage id="contactUs" defaultMessage="Contact us" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
})`
  label {
    cursor: pointer;
  }

  #contactpopup + label + div:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background: white;
    width: 10px;
    height: 10px;
  }

  position: relative;
  #contactpopup + label + div {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    transform: translateX(calc(-50% + 7px));
    min-width: 210px;
    min-height: 40px;
    background: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  }

  a {
    margin-left: 5px;
  }

  #contactpopup:checked + label[for="contactpopup"]:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  #contactpopup:checked + label + div {
    display: block;
  }

  ul {
    gap: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    list-style: none;
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid lightgrey;
    }
    li:last-of-type {
      border: none;
    }
    li:before {
      content: "";
      height: 2px;
      width: 0%;
      bottom: -1px;
      position: absolute;
      background: red;
      -webkit-transition: 0.3s ease all;
      transition: 0.3s ease all;
      left: 0%;
    }

    li:hover:before {
      width: 100%;
    }
  }
  a {
    color: black;
  }
`;

const User = styled(({ className }) => {
  const logout = useLogout();
  const history = useHistory();
  return (
    <div className={className}>
      {!localStorage.getItem("token") ? (
        <FontAwesomeIcon
          onClick={() => {
            history.push("/auth");
          }}
          icon={faSignInAlt}
        />
      ) : (
        <UserPopup logout={logout} />
      )}
    </div>
  );
})`
  margin: 0 10px;
  align-items: center;
  display: flex;
  svg {
    cursor: pointer;
    width: auto;
    height: 24px;
  }
`;

const SearchResult = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  top: 64px;
  background: white;
  ul {
    list-style: none;
    padding-left: 0;
  }
  box-shadow: 0 10px 8px 0px #0000006e;
  max-height: 50%;
  overflow-y: scroll;
`;

const Search = styled(({ className }) => {
  let [query, { data, loading }, set] = useSearch();
  const intl = useIntl();
  const ctx = useContext(State);

  const search = useCallback(
    (e) => {
      e.preventDefault();
      if (data) {
        e.target.reset();
        set({});
        return;
      }

      const fdata = new FormData(e.target);
      const v = fdata.get("query");
      if (!!v) {
        query({ variables: { query: v, channel: ctx.channel } });
      }

      return false;
    },
    [query, data, set, ctx.channel]
  );

  return (
    <div className={className}>
      <form onSubmit={search} action="#">
        <input type="checkbox" hidden id="showSearch" />
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: "search",
            defaultMessage: "Search...",
          })}
          name="query"
        />
        {!loading && data?.products?.edges?.length > 0 && (
          <SearchResult>
            <ul>
              {data?.products?.edges.map(({ node }) => {
                return (
                  <li key={node.id}>
                    <ProductLine onClick={(e) => set({})} {...node} />
                  </li>
                );
              })}
            </ul>
          </SearchResult>
        )}

        {(data && (
          <button type="submit">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )) || (
          <label htmlFor="showSearch">
            <FontAwesomeIcon icon={faSearch} />
          </label>
        )}
      </form>
    </div>
  );
})`
  #showSearch + input {
    width: 0;
  }

  #showSearch:checked + input {
    width: auto;
  }

  button {
    background: transparent;
    border: none;
    outline: none;
  }
  > form {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: end;
    position: relative;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  justify-content: end;
  position: relative;

  input {
    position: absolute;
    max-width: 100px;
    height: 32px;
    border: none;
    outline: none;
    font-size: 16px;
    margin-right: 28px;
  }
  svg {
    cursor: pointer;
    width: auto;
  }
  flex-grow: 1;
  margin-right: auto;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 10px;
`;

const Cart = styled(({ className }) => {
  const cart = useCart();
  const intl = useIntl();
  const detailsForm = useRef();
  const history = useHistory();
  const { data: meData } = useMe();
  const currentCheckout = JSON.parse(localStorage.getItem("checkout") || "{}");
  const [checkout] = useCreateCheckout();
  const ctx = useContext(State);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const checkboxRef = useRef(null);
  const [step, setStep] = useState(
    currentCheckout && cart.totalItems > 0 ? "details" : "summary"
  );

  const [updatesmpg] = useUpdateCheckout();
  const [finalize] = useFinalizeCheckout();

  useEffect(() => {
    let formData = localStorage.getItem("details");
    if (formData && detailsForm.current) {
      let a = JSON.parse(formData);
      a &&
        a.forEach(([k, v]) => {
          detailsForm.current.elements[k].value = v;
        });
    }
  }, [detailsForm]);

  const [hideBilling, showHideBilling] = useState(false);

  const details = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = [...formData.entries()].reduce((acc, [key, value]) => {
        const [rk, rv] = key.split(/\[(.+?)\]/g);
        if (!value) {
          return acc;
        }
        if (rv) {
          acc[rk] = acc[rk] || {};
          acc[rk][rv] = value;
        } else {
          acc[rk] = value;
        }

        return acc;
      }, {});

      if (data.sameAsAddress) {
        data.billingAddress = data.shippingAddress;
      }

      if (!localStorage.getItem("token")) {
        if (checkboxRef.current != null) {
          checkboxRef.current.checked = false;
        }
        history.push("/auth");
        localStorage.setItem(
          "details",
          JSON.stringify([...formData.entries()])
        );

        return;
      }

      const r = await checkout({
        variables: {
          ...data,
          channel: ctx.channel,
          email: localStorage.getItem("email"),
          lines: cart.items.map((item) => ({
            quantity: item.quantity,
            variantId: item.id,
          })),
        },
      });

      const errors = r.data?.checkoutCreate?.checkoutErrors || [];
      const checkoutData = r.data?.checkoutCreate?.checkout;
      if (errors.length === 0) {
        localStorage.setItem("checkout", JSON.stringify(checkoutData));
        setStep("checkout");
        setInfo(checkoutData);
        setError(null);
      } else {
        // show info
        setError(errors);
      }
      // proceed to info
    },
    [cart.items, checkout, ctx.channel, history]
  );

  const defaultBillingAddress = meData?.me?.addresses?.find?.(
    (address) => address.id === meData?.me?.defaultBillingAddress?.id
  );
  const defaultShippingAddress = meData?.me?.addresses?.find?.(
    (address) => address.id === meData?.me?.defaultShippingAddress?.id
  );

  return (
    <div className={className}>
      <input
        type="checkbox"
        id="cart"
        ref={checkboxRef}
        style={{
          display: "none",
        }}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {step === "details" && (
            <div
              onClick={() => setStep("summary")}
              style={{
                flexGrow: 1,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                style={{
                  width: 18,
                  height: 18,
                }}
                viewBox="0 0 477.175 477.175"
              >
                <g>
                  <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
                </g>
              </svg>
            </div>
          )}
          <label htmlFor="cart">
            <svg
              aria-hidden="true"
              focusable="false"
              style={{
                height: 18,
              }}
              role="presentation"
              viewBox="0 0 40 40"
            >
              <path d="M23.868 20.015L39.117 4.78c1.11-1.108 1.11-2.77 0-3.877-1.109-1.108-2.773-1.108-3.882 0L19.986 16.137 4.737.904C3.628-.204 1.965-.204.856.904c-1.11 1.108-1.11 2.77 0 3.877l15.249 15.234L.855 35.248c-1.108 1.108-1.108 2.77 0 3.877.555.554 1.248.831 1.942.831s1.386-.277 1.94-.83l15.25-15.234 15.248 15.233c.555.554 1.248.831 1.941.831s1.387-.277 1.941-.83c1.11-1.109 1.11-2.77 0-3.878L23.868 20.015z"></path>
            </svg>
          </label>
        </div>

        {step === "summary" && (
          <ul>
            {cart.items.length === 0 && (
              <div
                style={{
                  margin: "0 auto",
                }}
              >
                <h1>
                  <FormattedMessage
                    id="emptyCart"
                    defaultMessage="Your cart is empty"
                  />
                </h1>
              </div>
            )}
            {cart.items.map((item) => {
              return (
                <li key={item.id}>
                  <svg
                    onClick={(e) => {
                      e.preventDefault();
                      if (item.quantity - 1 <= 0) {
                        cart.removeItem(item.id);
                        return;
                      }
                      cart.updateItem(item.id, {
                        quantity: item.quantity - 1,
                      });
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13H5v-2h14v2z" fill="currentColor"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                  <ProductLine {...item} amount={item.quantity} />
                  <svg
                    onClick={(e) => {
                      e.preventDefault();
                      cart.updateItem(item.id, {
                        quantity: item.quantity + 1,
                      });
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                      fill="currentColor"
                    ></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </li>
              );
            })}
          </ul>
        )}

        <div
          style={{
            overflowY: "auto",
          }}
        >
          {step === "details" && (
            <Form action="#" onSubmit={details} ref={detailsForm}>
              <strong>
                <FormattedMessage
                  id="personal"
                  defaultMessage="Personal information"
                />
                :{" "}
              </strong>
              <div className="group">
                <input
                  type="text"
                  name="firstName"
                  required="required"
                  defaultValue={meData?.me?.firstName}
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage
                    id="firstName"
                    defaultMessage="First name"
                  />
                </label>
              </div>
              <div className="group">
                <input
                  type="text"
                  name="lastName"
                  required="required"
                  defaultValue={meData?.me?.lastName}
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="lastName" defaultMessage="Last name" />
                </label>
              </div>
              <div className="group">
                <input
                  type="text"
                  name="companyName"
                  defaultValue={defaultBillingAddress?.companyName}
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage
                    id="companyName"
                    defaultMessage="Company Name"
                  />
                </label>
              </div>
              <strong>
                <FormattedMessage id="address" defaultMessage="Address" />:{" "}
              </strong>

              <div className="group">
                <input
                  type="text"
                  name="shippingAddress[city]"
                  required="required"
                  defaultValue={defaultShippingAddress?.city}
                  focusable
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="city" defaultMessage="City" />
                </label>
              </div>
              <div className="group">
                <input
                  type="text"
                  name="shippingAddress[postalCode]"
                  defaultValue={defaultShippingAddress?.postalCode}
                  required="required"
                  focusable
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="zip" defaultMessage="Zip/Post code" />
                </label>
              </div>
              <div className="group">
                <select
                  name="shippingAddress[country]"
                  defaultValue={defaultShippingAddress?.country}
                  required="required"
                  focusable
                >
                  <option value="HU">
                    {intl.formatMessage({
                      id: "hu",
                      defaultMessage: "Hungary",
                    })}
                  </option>
                  <option value="DE">
                    {intl.formatMessage({
                      id: "de",
                      defaultMessage: "Germany",
                    })}
                  </option>
                  <option value="GB">
                    {intl.formatMessage({
                      id: "gb",
                      defaultMessage: "United kingdom",
                    })}
                  </option>
                </select>
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="country" defaultMessage="Country" />
                </label>
              </div>
              <div className="group">
                <input
                  type="tel"
                  name="shippingAddress[phone]"
                  defaultValue={defaultShippingAddress?.phone}
                  required="required"
                  focusable
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage id="phone" defaultMessage="Phone" />
                </label>
              </div>
              <div className="group">
                <input
                  type="text"
                  name="shippingAddress[streetAddress1]"
                  defaultValue={defaultShippingAddress?.streetAddress1}
                  required="required"
                  focusable
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage
                    id="street1"
                    defaultMessage="Street address 1"
                  />
                </label>
              </div>
              <div className="group">
                <input
                  type="text"
                  name="shippingAddress[streetAddress2]"
                  defaultValue={defaultShippingAddress?.streetAddress2}
                  focusable
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>
                  <FormattedMessage
                    id="street2"
                    defaultMessage="Street address 2"
                  />
                </label>
              </div>
              <div className="group">
                <input
                  type="checkbox"
                  id="sameAsAddress"
                  name="sameAsAddress"
                  style={{
                    width: "10px",
                  }}
                  onChange={() => showHideBilling(!hideBilling)}
                />
                <span className="error"></span>
                <span className="highlight"></span>
                <label
                  htmlFor="sameAsAddress"
                  style={{
                    left: "12px",
                    top: -1,
                  }}
                >
                  <FormattedMessage
                    id="sameAsAddress"
                    defaultMessage="Same as address"
                  />
                </label>
              </div>
              <details
                className="group"
                style={{
                  visibility: hideBilling ? "hidden" : "visible",
                }}
              >
                <summary>
                  <strong>
                    <FormattedMessage
                      id="billingAddress"
                      defaultMessage="Billing address:"
                    />
                  </strong>
                </summary>

                <div className="group">
                  <input
                    type="text"
                    name="billingAddress[city]"
                    defaultValue={defaultBillingAddress?.city}
                  />
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage id="city" defaultMessage="City" />
                  </label>
                </div>
                <div className="group">
                  <input
                    type="text"
                    name="billingAddress[postalCode]"
                    defaultValue={defaultBillingAddress?.postalCode}
                  />
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage id="zip" defaultMessage="Zip/Post code" />
                  </label>
                </div>
                <div className="group">
                  <select
                    name="billingAddress[country]"
                    defaultValue={defaultBillingAddress?.country}
                    focusable
                  >
                    <option value=""></option>
                    <option value="HU">
                      {intl.formatMessage({
                        id: "hu",
                        defaultMessage: "Hungary",
                      })}
                    </option>
                    <option value="DE">
                      {intl.formatMessage({
                        id: "de",
                        defaultMessage: "Germany",
                      })}
                    </option>
                    <option value="GB">
                      {intl.formatMessage({
                        id: "gb",
                        defaultMessage: "United kingdom",
                      })}
                    </option>
                  </select>
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage id="country" defaultMessage="Country" />
                  </label>
                </div>
                <div className="group">
                  <input
                    type="tel"
                    name="billingAddress[phone]"
                    defaultValue={defaultBillingAddress?.phone}
                  />
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage id="phone" defaultMessage="Phone" />
                  </label>
                </div>
                <div className="group">
                  <input
                    type="text"
                    name="billingAddress[streetAddress1]"
                    defaultValue={defaultBillingAddress?.streetAddress1}
                  />
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage
                      id="street1"
                      defaultMessage="Street address 1"
                    />
                  </label>
                </div>
                <div className="group">
                  <input
                    type="text"
                    name="billingAddress[streetAddress2]"
                    defaultValue={defaultBillingAddress?.streetAddress2}
                  />
                  <span className="error"></span>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage
                      id="street2"
                      defaultMessage="Street address 2"
                    />
                  </label>
                </div>
              </details>
              {error && (
                <div
                  style={{
                    color: "black",
                    margin: 20,
                  }}
                >
                  <h3>
                    <FormattedMessage
                      id="ops"
                      defaultMessage="Ops, Something went wrong"
                    />
                  </h3>
                  <p>
                    <FormattedMessage
                      id="ensure"
                      defaultMessage="Please ensure that the following fields are valid:"
                    />
                  </p>
                  {error.map((err) => (
                    <span style={{ display: "block" }}>
                      - {err.field}:&nbsp;
                      <FormattedMessage id={err.code} defaultMessage="" />
                    </span>
                  ))}
                </div>
              )}
              {step === "details" && (
                <>
                  <Button type="submit">
                    <FormattedMessage id="continue" defaultMessage="Continue" />
                  </Button>
                </>
              )}
            </Form>
          )}
          {(step === "summary" || step === "checkout") && (
            <div>
              <FormattedMessage id="total" defaultMessage="Total" />:{" "}
              {intl.formatNumber(cart.cartTotal, {
                style: "currency",
                currency: cart.items?.[0]?.currency,
              })}
            </div>
          )}
          {step === "checkout" && (
            <>
              <Form
                onSubmit={async (e) => {
                  const d = new FormData(e.target);
                  e.preventDefault();

                  const smId = d.get("smId");
                  const pgId = d.get("pgId");

                  const r = await updatesmpg({
                    variables: {
                      checkoutId: currentCheckout.id,
                      shippingMethodId: smId,
                      gateway: pgId,
                      token: currentCheckout.token,
                      amount: cart.cartTotal,
                    },
                  });

                  if (r.data) {
                    const finalized = await finalize({
                      variables: {
                        checkoutId: currentCheckout.id,
                      },
                    });

                    const confirmationNeeded =
                      finalized.data?.checkoutComplete?.confirmationNeeded;
                    const isPaid =
                      finalized.data?.checkoutComplete?.order?.isPaid;

                    if (isPaid && !confirmationNeeded) {
                      localStorage.removeItem("checkout");
                      localStorage.removeItem("details");
                      cart.emptyCart();
                      setStep("summary");
                      // close cart
                      history.push(`/order/${currentCheckout.id}`);
                    }
                  }

                  return false;
                }}
              >
                <div className="group">
                  <select name="smId">
                    {info?.availableShippingMethods?.map?.((sm) => {
                      return (
                        <option value={sm.id}>
                          {intl.formatMessage({
                            id: sm.name,
                            defaultMessage: sm.name,
                          })}
                        </option>
                      );
                    })}
                  </select>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage
                      id="shippingMethod"
                      defaultMessage="Shipping method"
                    />
                  </label>
                </div>
                <div className="group">
                  <select name="pgId">
                    {info?.availablePaymentGateways?.map?.((sm) => {
                      return (
                        <option value={sm.id}>
                          {intl.formatMessage({
                            id: sm.name,
                            defaultMessage: sm.name,
                          })}
                        </option>
                      );
                    })}
                  </select>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>
                    <FormattedMessage
                      id="paymentMethod"
                      defaultMessage="Payment method"
                    />
                  </label>
                </div>
                <Button type="submit">
                  <FormattedMessage id="checkout" defaultMessage="Checkout" />
                </Button>
              </Form>
            </>
          )}
          {step === "summary" && (
            <Button
              disabled={cart.cartTotal === 0}
              onClick={() => setStep("details")}
            >
              <FormattedMessage id="proceed" defaultMessage="Proceed" />
            </Button>
          )}
        </div>
      </div>
      <label htmlFor="cart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <i>{cart.totalItems}</i>
      </label>
    </div>
  );
})`
  position: relative;

  #cart:checked ~ label:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: red;
    display: block;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  label:after {
    transition: opacity 0.3s;
    opacity: 0;
  }

  #cart:checked + div {
    display: flex;
    right: 0;
    transform: translateX(0%);
  }

  margin: 0 10px;
  > div {
    transition: 0.3s all;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-width: 50vw;
    max-width: 500px;
    height: 100vh;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1;
    background: white;
    color: black;
    box-sizing: border-box;
    padding: 20px;
  }

  ul {
    padding-left: 0;
    flex-grow: 1;
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;
    }
  }

  ul + div {
    display: flex;
    flex-direction: column;
  }

  i {
    position: absolute;
    border-radius: 50%;
    background: white;
    font-style: normal;
    font-size: 9px;
    padding: 4px;
    width: 16px;
    height: 16px;
    line-height: 7px;
    border: solid 1px black;
    right: -4px;
    top: -4px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    height: 28px;
  }
`;

const Languages = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px;
  > img {
    cursor: pointer;
  }
`;

const Layout = styled(({ children, className }) => {
  const ctx = useContext(State);
  const history = useHistory();
  return (
    <div className={className}>
      <GlobalStyle />
      <Header>
        <input type="checkbox" id="menu" style={{ display: "none" }} />
        <Logo
          src="/icon-512x512.png"
          onClick={(e) => {
            history.push("/");
          }}
        />
        <Nav>
          <Link to="/">
            <FormattedMessage id="home" defaultMessage="Home" />
          </Link>
          <Navs />
        </Nav>
        <Search />
        <Actions>
          <ContactPopup />
          <User />
          <Cart />
          <label htmlFor="menu">
            <Hamburger />
            <Backdrop />
          </label>
        </Actions>
      </Header>
      <Main>{children}</Main>
      <Footer>
        {/*<ReactLanguageSelect languages={['en', 'hu', 'de']} />*/}
        <Languages>
          <img
            alt="en"
            onClick={() => ctx.setLocale("en")}
            src="https://hatscripts.github.io/circle-flags/flags/gb.svg"
            width="48"
          />
          <img
            alt="hu"
            onClick={() => ctx.setLocale("hu")}
            src="https://hatscripts.github.io/circle-flags/flags/hu.svg"
            width="48"
          />
          <img
            alt="de"
            onClick={() => ctx.setLocale("de")}
            src="https://hatscripts.github.io/circle-flags/flags/de.svg"
            width="48"
          />
        </Languages>
        <Logo src="/icon-512x512.png" />
        <br />
        <p>
          <strong>
            <FormattedMessage
              id="footerHead"
              defaultMessage="© 2021, SUN2000 HIVATALOS HUAWEI NAGYKERESKEDÉS"
            />
          </strong>
          <br />
          <FormattedMessage
            id="footerCopy"
            defaultMessage="Powered by Solar Hero Zrt. Minden jog fenntartva!"
          />
        </p>
      </Footer>
    </div>
  );
})`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Main} {
    flex-grow: 1;
  }
`;

const MyAddresses = styled(({ className }) => {
  const { data, error, loading, refetch } = useMe();
  const [deleteAddress] = useDeleteAddress();
  const [setAddress] = useSetAddress();
  const [addAddress] = useAddAddress();
  const intl = useIntl();

  const addNewAddress = useCallback(
    (e) => {
      debugger;
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = [...formData.entries()].reduce((acc, [key, value]) => {
        const [rk, rv] = key.split(/\[(.+?)\]/g);
        if (!value) {
          return acc;
        }
        if (rv) {
          acc[rk] = acc[rk] || {};
          acc[rk][rv] = value;
        } else {
          acc[rk] = value;
        }

        return acc;
      }, {});
      addAddress({
        variables: {
          input: data,
        },
      }).then(() => refetch());
    },
    [addAddress, refetch]
  );

  const setAdddressCallback = useCallback(
    (id, type) => () => {
      setAddress({
        variables: {
          id,
          type,
        },
      }).then(() => refetch());
    },
    [setAddress, refetch]
  );

  if (error || !data?.me) return null;
  if (loading) return "...";
  const { me } = data;
  const { defaultBillingAddress, defaultShippingAddress } = me || {
    defaultBillingAddress: {
      id: "",
    },
    defaultShippingAddress: { id: "" },
  };
  const { id: billingAddress } = defaultBillingAddress || {};
  const { id: shippingAddress } = defaultShippingAddress || {};
  return (
    <div className={className}>
      <h2>
        <FormattedMessage id="addresses" defaultMessage="Addresses" />
      </h2>
      <details>
        <summary>
          <FormattedMessage
            id="addNewAddress"
            defaultMessage="Add new address"
          />
        </summary>
        <Form action="#" onSubmit={addNewAddress}>
          <div className="group">
            <input type="text" name="companyName" required="required" />
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage
                id="companyName"
                defaultMessage="Company Name"
              />
            </label>
          </div>
          <div className="group">
            <select name="country" required="required" focusable>
              <option value="HU">
                {intl.formatMessage({
                  id: "hu",
                  defaultMessage: "Hungary",
                })}
              </option>
              <option value="DE">
                {intl.formatMessage({
                  id: "de",
                  defaultMessage: "Germany",
                })}
              </option>
              <option value="GB">
                {intl.formatMessage({
                  id: "gb",
                  defaultMessage: "United kingdom",
                })}
              </option>
            </select>
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage id="country" defaultMessage="Country" />
            </label>
          </div>
          <div className="group">
            <input type="text" name="city" required="required" />
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage id="city" defaultMessage="City" />
            </label>
          </div>
          <div className="group">
            <input type="text" name="postalCode" required="required" />
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage
                id="postalCode"
                defaultMessage="Zip/Postal code"
              />
            </label>
          </div>
          <div className="group">
            <input type="text" name="streetAddress1" required="required" />
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage
                id="streetAddress1"
                defaultMessage="Street address 1"
              />
            </label>
          </div>
          <div className="group">
            <input type="text" name="streetAddress2" />
            <span className="error"></span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>
              <FormattedMessage
                id="streetAddress2"
                defaultMessage="Street address 2"
              />
            </label>
          </div>
          <Button type="submit">
            <FormattedMessage id="addNew" defaultMessage="Add new" />
          </Button>
        </Form>
      </details>

      <ul>
        {me?.addresses?.length === 0 && (
          <h2>
            <FormattedMessage
              id="yourAddressesEmpty"
              defaultMessage="You don't have any addresses"
            />
          </h2>
        )}
        {(me?.addresses || []).map((address) => {
          return (
            <li key={address.id}>
              <span>
                {address.postalCode} {address.city}, {address.streetAddress1}{" "}
                {address.streetAddress2}
              </span>

              <span>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => {
                    deleteAddress({
                      variables: {
                        id: address.id,
                      },
                    });
                  }}
                  title={intl.formatMessage({
                    id: "delete",
                    defaultMessage: "Delete",
                  })}
                />
                &nbsp;
                {(billingAddress !== address.id && (
                  <FontAwesomeIcon
                    onClick={setAdddressCallback(address.id, "BILLING")}
                    icon={faLandmark}
                    title={intl.formatMessage({
                      id: "primaryBilling",
                      defaultMessage: "Set as primary billing address",
                    })}
                  />
                )) || (
                  <FontAwesomeIcon
                    color="red"
                    icon={faLandmark}
                    title={intl.formatMessage({
                      id: "primaryBilling",
                      defaultMessage: "Set as primary billing address",
                    })}
                  />
                )}
                &nbsp;
                {(shippingAddress !== address.id && (
                  <FontAwesomeIcon
                    onClick={setAdddressCallback(address.id, "SHIPPING")}
                    icon={faStar}
                    title={intl.formatMessage({
                      id: "primaryShipping",
                      defaultMessage: "Set as shipping billing address",
                    })}
                  />
                )) || (
                  <FontAwesomeIcon
                    color="red"
                    icon={faStar}
                    title={intl.formatMessage({
                      id: "primaryShipping",
                      defaultMessage: "Set as shipping billing address",
                    })}
                  />
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
})`
  max-width: 720px;
  padding: 0 20px;
  h2 {
    margin: 20px;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      svg {
        cursor: pointer;
      }
    }
  }
  margin: 0 auto;
  h3 {
    font-size: 22px;
    margin: 20px 0;
  }
  details {
    font-size: 22px;
    user-select: none;
    cursor: pointer;
    &[open] > summary:after {
      transform: rotate(90deg);
    }
  }
  summary {
    ::marker,
    ::-webkit-details-marker {
      display: none;
    }
    list-style: none;
    cursor: pointer;
    position: relative;
    width: fit-content;

    :after {
      right: -30px;
      top: -6px;
      width: 40px;
      height: 40px;
      position: absolute;

      content: "";
      border-radius: 50%;
      background: var(--primaryBackgroundColor);
      transition: transform 0.3s;
      background-position: center;
      background-repeat: no-repeat;
      // TODO: Remove
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xMC41OSAwTDYgNC4zMjY1OUwxLjQxIDBMMCAxLjMzMTk4TDYgN0wxMiAxLjMzMTk4TDEwLjU5IDBaIiBmaWxsPSIjRkY1QTVBIi8+PC9zdmc+);
    }
  }
`;

const MyOrders = styled(({ className }) => {
  const { data, error, loading } = useMe();
  const intl = useIntl();
  if (error || !data?.me) return null;
  if (loading) return "...";
  const { me } = data;
  const {
    orders: { edges = [] },
  } = me || {};
  return (
    <div className={className}>
      <h2>
        <FormattedMessage id="myOrders" defaultMessage="My orders" />
      </h2>
      <ul>
        {edges
          .flatMap((e) => e.node)
          .map((order) => {
            return (
              <li key={order.created}>
                <div className="details">
                  <div>
                    <strong>
                      <FormattedMessage
                        id="orderStatus"
                        defaultMessage="Status"
                      />
                      :
                    </strong>{" "}
                    {snakeCase(order.status)}
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="orderChannel"
                        defaultMessage="Channel"
                      />
                      :
                    </strong>{" "}
                    {order.channel?.name}
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage id="orderDate" defaultMessage="Date" />:
                    </strong>
                    {intl.formatDate(order.created, {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="orderTotal"
                        defaultMessage="Total"
                      />
                      :
                    </strong>

                    {intl.formatNumber(order.total.net.amount, {
                      style: "currency",
                      currency: order.total.net.currency,
                    })}
                  </div>
                  {order.invoices?.length > 0 && (
                    <div>
                      <strong>
                        <FormattedMessage
                          id="orderInvoices"
                          defaultMessage="Invoices"
                        />
                        :
                      </strong>{" "}
                      <div
                        style={{
                          display: "flex",
                          gap: 4,
                        }}
                      >
                        {order.invoices.map((x) => (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            key={x.number}
                            href={x.url}
                          >
                            {x.number}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <details>
                  <summary>
                    <strong>
                      <FormattedMessage id="lines" defaultMessage="Lines" />
                    </strong>
                  </summary>
                  <ul>
                    {order.lines.map((line) => (
                      <li key={line.productName}>
                        {line.productName} x {line.quantity} -{" "}
                        {intl.formatNumber(line.totalPrice.net.amount, {
                          style: "currency",
                          currency: line.totalPrice.net.currency,
                        })}
                      </li>
                    ))}
                  </ul>
                </details>
              </li>
            );
          })}
      </ul>
    </div>
  );
})`
  padding: 0 20px;
  .details {
    display: flex;
    flex-direction: column;
    > div:nth-of-type(2n) {
      background-color: lightgrey;
    }
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
  ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    li {
      margin: 20px 0;
      position: relative;
    }
  }
  h2 {
    margin: 20px;
    text-align: center;
  }
  margin: 0 auto;
  max-width: 720px;

  details {
    user-select: none;

    &[open] > summary:after {
      transform: rotate(90deg);
    }
  }
  summary {
    ::marker,
    ::-webkit-details-marker {
      display: none;
    }
    list-style: none;
    cursor: pointer;
    position: relative;
    width: fit-content;

    :after {
      right: -30px;
      top: -12px;
      width: 40px;
      height: 40px;
      position: absolute;

      content: "";
      border-radius: 50%;
      background: var(--primaryBackgroundColor);
      transition: transform 0.3s;
      background-position: center;
      background-repeat: no-repeat;
      // TODO: Remove
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xMC41OSAwTDYgNC4zMjY1OUwxLjQxIDBMMCAxLjMzMTk4TDYgN0wxMiAxLjMzMTk4TDEwLjU5IDBaIiBmaWxsPSIjRkY1QTVBIi8+PC9zdmc+);
    }
  }
`;

function App() {
  const [locale, setLocale] = useState(localStorage.getItem("locale") || window.location.hostname?.split('.').slice(-1)[0] || "en");
  const [messages, setMessages] = useState(undefined);
  const [channel] = useState(
    localStorage.getItem("ti") === "true" ? "channel-ti" : "default-channel"
  );
  console.log(localStorage.getItem("ti"), channel);
  useEffect(() => {
    async function loadLocaleData(locale) {
      let messages = {};
      switch (locale) {
        case "hu":
          messages = await import("./compiled-lang/hu.json");
          break;
        default:
          messages = en;
      }
      setMessages(messages);
      localStorage.setItem("locale", locale);
    }
    loadLocaleData(locale);
  }, [locale, setMessages]);

  return (
    <State.Provider
      value={{
        locale,
        channel,
        setLocale(locale) {
          setLocale(locale);
          if (channel.includes("ti")) {
            return;
          }
          //setChannel(`channel-${locale}`)
        },
      }}
    >
      <IntlProvider defaultLocale="en" locale={locale} messages={messages}>
        <CartProvider>
          <ApolloProvider client={client}>
            <Router>
              <Layout>
                <Switch>
                  <Route path="/order/:id">
                    <OrderPlaced />
                  </Route>
                  <Route path="/collection/:slug">
                    <CollectionPage />
                  </Route>
                  <Route path="/category/:slug">
                    <CategoryPage />
                  </Route>
                  <Route path="/user/addresses">
                    <MyAddresses />
                  </Route>
                  <Route path="/user/orders">
                    <MyOrders />
                  </Route>
                  <Route path="/page/:slug">
                    <Page />
                  </Route>
                  <Route path="/product/:slug">
                    <ProductPage />
                  </Route>
                  <Route path="/register">
                    <AuthPage type="register" />
                  </Route>
                  <Route path="/auth/reset">
                    <AuthPage type="pwreset" />
                  </Route>
                  <Route path="/auth">
                    <AuthPage />
                  </Route>
                  <Route path="/reset/:email/:token">
                    <AuthPage type="reset" />
                  </Route>
                  <Route path="/">
                    <HomePage />
                  </Route>

                  <Route>
                    <FormattedMessage id="error" defaultMessage="Error" />
                  </Route>
                </Switch>
              </Layout>
            </Router>
          </ApolloProvider>
        </CartProvider>
      </IntlProvider>
    </State.Provider>
  );
}

export default App;

/*
 const { data, error, loading } = useQuery(
    gql`
      query CollectionMany($first: Int!) {
        products(first: $first) {
          ...ProductConnection
        }
        collections(first: $first) {
          edges {
            node {
              id

              name
              slug
            }
          }
        }
      }
      fragment ProductConnection on ProductCountableConnection {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            name
            description
            slug
            pricing {
              priceRange {
                start {
                  net {
                    amount
                  }
                }
              }
            }
            media {
              url
              alt
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 10,
      },
    }
  )

  if (loading) return 'Loading'
  if (error) return 'error' */
