import styled from 'styled-components'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useCategory } from './hooks'
import { Pagination } from './pagination'
import { Grid, Product } from './product'
import { Helmet } from 'react-helmet'

export const CategoryPage = styled(props => {
  const {
    params: { slug },
  } = useRouteMatch()
  const [current, setCurrent] = useState(undefined);
  const { data, error, loading } = useCategory(slug, current)
  
  if (error || loading) return null

  const name = data?.category?.translation?.name || data?.category?.name

  const totalCount = data?.category?.products?.totalCount
  const pages = (totalCount - (totalCount % 10) + 10) / 10
  const pageInfo = data?.category?.products?.pageInfo

  return (
    <div className={props.className}>
      <Helmet>
        <title>Sun2000 {data?.category?.seoTitle || ""}</title>
        <meta name="description" content={data?.category?.seoDescription || ""} />
      </Helmet>
      <h1>{name}</h1>
      <Grid>
        {data.category.products.edges.map(({ node: product }) => (
          <Product key={product.id} {...product} />
        ))}
      </Grid>
      <Pagination onChange={setCurrent} current={0} pages={pages} {...pageInfo} />
    </div>
  )
})`
    padding: 40px 0;
    h1 {
        text-align: center;
        margin: 20px 0;
    }
`
